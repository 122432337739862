import React from "react"
import Container from "../components/Container"

import Layout from "../components/layout"
import BFLogo from "../components/Logos/BFLogo"
import SEO from "../components/seo"

const BillFixers: React.FC = () => (
  <Layout>
    <SEO title="Preferr" />
    <Container>
      <BFLogo size={120} className="mb-8" />
      <div className="text-base font-sans text-gray-500">
        Lead Engineer &amp; Designer
      </div>
      <div className="font-mono text-gray-500 text-sm">2015 - Present</div>
      <div className="grid grid-cols-1 sm:grid-cols-3 sm:gap-12">
        <div className="font-sans text-sm mt-6 col-span-2">
          <p>
            BillFixers is a bill negotiation service started by some old friends
            of mine. They negotiate better rates on any kind of recurring bill,
            from telecom bills to waste management bills, and anything in
            between.
          </p>
          <p>
            I've worked for them as their primary developer in a freelance
            capacity since they launched.
          </p>
          <p>
            I built the backend and frontend of BillFixers, using Ruby on Rails,
            GraphQL, React, and TypeScript. I also helped design the frontend
            app, which has been used by 20,000+ customers since 2015.
          </p>
        </div>
        <div className="p-4 bg-gray-900 rounded-lg">
          {/* <div className="font-mono text-gray-200 text-base text-center">
            Tech Stack
          </div> */}
          <div className="text-xs text-gray-600 font-mono">
            Languages/Frameworks
          </div>
          <ul className="list-none text-gray-200 font-sans text-sm mt-4">
            <li>Ruby on Rails</li>
            <li>GraphQL</li>
            <li>React</li>
            <li>TypeScript</li>
            <li>Sidekiq</li>
            <li>Postges</li>
            <li>Tailwind</li>
          </ul>
          <div className="text-xs text-gray-600 font-mono">
            Platforms/Services
          </div>
          <ul className="list-none text-gray-200 font-sans text-sm mt-4">
            <li>Heroku</li>
            <li>Netlify</li>
            <li>Mandrill</li>
            <li>Stripe</li>
            <li>Plaid</li>
          </ul>
        </div>
      </div>
      {/* <div className="font-sans text-sm mt-16">
        <h3 className="font-medium text-gray-300 font-serif">
          Cohub Inventory, a Shopify app
        </h3>
        <p>
          We began developing an inventory counting application in 2019 with the
          intent to sell it on Shopify's app marketplace. The app is really two
          apps: one is a webapp that runs inside of Shopify, and the other is a
          companion mobile app for iOS and Android devices.
        </p>
        <p>
          The mobile app allows users to conduct inventory counts with anyone
          else who has a smartphone. Counting inventory normally sucks, so being
          able to divide and conquer and use your phone as a barcode scanner is
          a tremendous time saver.
        </p>
      </div> */}
    </Container>
  </Layout>
)

export default BillFixers
